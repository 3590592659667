import { Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap
import styles from '../sample-page/styles.module.scss'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { CardBody, Card } from "reactstrap"
// Install required Swiper components


const TestimonialsSlider = () => {

    const breakpoints = {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        480: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1000: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    };

    return (

        <Row id="testimonials" className={styles.homeRow}>
            <Col lg={12} md={12} sm={12} xs={12} className={styles.mainTestimonials}>
                <h1 className={styles.testimonialsHeader}>
                    Testimonial
                </h1>
                <hr className={styles.smallUnderline} />
                <br />
                <br />
                <Swiper
                    className={styles.swiper}
                    breakpoints={breakpoints}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                //className="mySwiper"
                >
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type 
                                    specimen book. It has survived not only five centuries, but also the leap into
                                    electronic typesetting, remaining essentially unchanged. 
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    It is a long established fact that a reader will be distracted by the readable content
                                    of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                    here', making it look like readable English. Many desktop publishing packages and web
                                    page editors now use Lorem Ipsum as their default model text.
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
                                    middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
                                    making this the first true generator on the Internet.  
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                                    eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                                    voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                    voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I
                                    will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth,
                                    the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure,
                                    but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.
                                    
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti
                                    atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                    sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum
                                    facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
                                    impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                </Swiper>
            </Col>
        </Row>
      /*  <Row id="testimonials" className={styles.homeRow}>
            <Col lg={12} md={12} sm={12} xs={12} className={styles.mainTestimonials}>
                <h1 className={styles.testimonialsHeader}>
                    Testimonials
                </h1>
                <br/>
                <Swiper
                    className={styles.swiper}
                    breakpoints={breakpoints}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                     Slide 1 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    Slide 2 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    Slide 3 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    Slide 4 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    Slide 5 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Card className={styles.imgcardDiv}>
                            <CardBody className={styles.cardBody}>
                                <Row>
                                    Slide 6 Content
                                </Row>
                            </CardBody>
                        </Card>
                    </SwiperSlide>
                </Swiper>
            </Col>
        </Row>*/
    );
};

export default TestimonialsSlider;
