import React, { useState } from "react";
import styles from "./styles.module.scss";
import {Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import Logo from "../../image/logo.png"
function LandingLayout({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const handleMouseEnter = () => setDropdownOpen(true);
    const handleMouseLeave = () => setDropdownOpen(false);

    return (
        <div className="login-page">
            <Navbar className={styles.mainContent} light expand="md">
                <NavbarBrand href="/">
                    <img src={Logo} className={styles.authLogoImg} />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink href="#home" className={styles.navItem}>HOME</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#services" className={styles.navItem}>SERVICES</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            isOpen={dropdownOpen}
                        >
                            <DropdownToggle nav caret className={styles.navItem}>
                                PROJECTS
                            </DropdownToggle>
                            <DropdownMenu right className={styles.dropdownMenu}>
                                <DropdownItem href="#residential" className={styles.dropdownText} >Residential</DropdownItem>
                                <DropdownItem href="#commercial" className={styles.dropdownText}>Commercial</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <NavLink href="#testimonials" className={styles.navItem}>TESTIMONIAL</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#contact" className={styles.navItem}>CONTACT</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );

}

export default LandingLayout;
