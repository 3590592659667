import React from 'react';
import styles from "./styles.module.scss";
import Logo from "../image/logoImage.jpg"
import { Row, Col } from "reactstrap";
import Header from "../components/header";
import Services from "../services/index";
import Projects from '../projects/index';
import Testimonial from '../testimonial/index';
function Home() {
    return (
        <>
            {/*<Header />*/}
            <Row className={styles.homeRow}>
                <Col className={styles.homeMainDiv} >
                    <div >
                        <img src={Logo} className={styles.authLogoImg} />
                    </div>
                    <br />
                    <br />
                    <br />
                    <h1 className={styles.commingSoon}>
                        Coming Soon...
                    </h1>
                </Col>
            </Row>
            {/*  <Services />
            <Projects />
            <Testimonial />*/}


        </>
    );
}

export default Home;
