import React from 'react';
import styles from "../sample-page/styles.module.scss";
import { Row, Col } from "reactstrap";
function Services() {
    return (
        <>
            <Row id="services" className={styles.homeRow}>
                <Col className={styles.mainColGray}>
                    <h1 className={styles.headerValue}>
                        Services
                    </h1>
                    <hr className={styles.smallUnderline} />
                    <br />
                    <p className={styles.textValue}>
                        Conceptual Design: <br />
                        Aesthetic review to understand client's likes and requirements.
                        Space planning to study and review initial design options Initial material,
                        finish and furniture suggestions 3-Dimensional renderings by hand or computer
                        generated Initial cost analysis of the design concept.
                        <br />
                        Design Proposals:
                        <br />
                        Structural changes plan Floor plan Proposed layout plan
                        Electrical plan Design Work/Construction Drawing including,
                        but not limited to: Elevation and Isometric Views Drawings include
                        custom-designed woodwork, cabinetry, storage units, paneling, etc.
                        Budget Proposals: udgeting of all decorative materials to be used in
                        the project Budgeting of all material, furniture, finishes udgeting
                        of labour and design fee xecution Areas/Expertise: Masonary Works
                        Tiling Works Painting and Polishing Works Aluminium Works and Mosquito
                        proofing False ceiling works includes gypsum, PVC and wood Electrical and
                        plumbing works Partitioning works Carpentry works Air conditioning guidance
                        Appliance Selection Lighting Selection Curtains and Blinds Selection Furniture
                        selection
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Services;
