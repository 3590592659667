import React from 'react';
import styles from "../sample-page/styles.module.scss";
import { Row, Col } from "reactstrap";
function Projects() {
    return (
        <>
            <Row id="residential" className={styles.homeRow}>
                <Col className={styles.mainColBlack}>                    
                        <h1 className={styles.headerValue}>
                            Residential
                    </h1>
                    <hr className={styles.smallUnderline} />
                        <br />
                        <p className={styles.textValue}>
                            We help our clients discover their own individuality and create a unique space
                            which reflects their personality and style,  at the same time being functional
                            and long lasting. We guide you through each design phase, from the conceptual
                            plans to unique selections of finishes & furnishings and offer recommendations
                            that support informed purchasing decisions.

                            We help our clients discover their own individuality and create a unique space
                            which reflects their personality and style,  at the same time being functional
                            and long lasting. We guide you through each design phase, from the conceptual
                            plans to unique selections of finishes & furnishings and offer recommendations
                            that support informed purchasing decisions.
                        </p>                   
                </Col>
            </Row>

            <Row id="commercial" className={styles.homeRow}>
                <Col className={styles.mainCommercial}>
                    <h1 className={styles.headerValue}>
                        Commercial
                    </h1>
                    <hr className={styles.smallUnderline} />
                    <br />
                    <p className={styles.textValue}>
                        We believe that your working space must be usable, functional,
                        make a unique design statement and have the technological support
                        to function to its best capability. We believe that your working
                        space must be usable, functional,make a unique design statement
                        and have the technological support to function to its best capability.
                    </p>
                </Col>
            </Row>


        </>
    );
}

export default Projects;
