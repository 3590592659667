import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Ensure Switch is imported correctly
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from '../src/home';
import SamplePage from '../src/sample-page';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/sample-page" component={SamplePage} />
                </Switch>
            </div>
        </Router>
         /*<div className="App">
            <Home />
        </div>*/
    );
}

export default App;
